@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {

  font-family: 'Poppins', sans-serif;

  --background-color: #cbddeb;

  --box: #ededed;


  --big-border: 12px;
  --small-border: 6px;

  --box-shadow-1: 10px 10px 49px -40px rgba(0,0,0,0.75);
  --box-shadow-2: 10px 10px 50px -12px rgba(0,0,0,0.75);

  --text-color-1: #707070;

  --primary-color: #e7b40c;

  --transition-1: all ease 0.2s;
  --transition-2: all ease 0.3s;
  --transition-3: all ease 0.5s;

}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  color: var(--text-color-1)
}
